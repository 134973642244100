import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { KEYS, GAME_STATUS } from 'helpers/'
import * as actions from 'actions/'
import { TimerStyled, TimerValue } from './styles'

const INITIAL_COUNT = 60
const FINAL_COUNT = 0

const draw = (angle) => {
  const X = 100 / 2
  const Y = 100 / 2
  const R = 40

  // El ángulo de partida = ap y el ángulo final = af
  const af = (Math.PI / 180) * (angle - 90)
  // Las coordenadas del punto final en la circunferencia
  const Xaf = X + R * Math.cos(af)
  const Yaf = Y + R * Math.sin(af)
  //comando para el Path
  let comando = `M ${X} ${Y}`
  comando += `L ${X} ${Y - R}`

  if (angle > 180) {
    comando += `A ${R} ${R} 0 0 1 ${X} ${Y + R}`
  }

  comando += `A ${R} ${R} 0 0 1 ${Xaf} ${Yaf}`
  comando += 'Z'
  return comando
}

const FILL_BLUE = 'rgba(0,145,218,50%)'
const FILL_RED = 'rgba(255,0,0,50%)'

const Timer = ({ className }) => {
  const dispatch = useDispatch()
  const [count, setCount] = useState(INITIAL_COUNT)
  const gameStatus = useSelector((state) => state.game?.[KEYS.GAME_STATUS].value)
  const modals = useSelector((state) => state.modals[KEYS.MODALS])
  const intervalRef = useRef()

  const updateCount = useCallback(() => {
    setCount((prev) => prev - 1)
  }, [])

  useEffect(() => {
    if (gameStatus === GAME_STATUS.ONGOING) {
      intervalRef.current = setInterval(updateCount, 1000)
    } else if (gameStatus === GAME_STATUS.FINISHED) {
      clearInterval(intervalRef.current)
    } else {
      setCount(INITIAL_COUNT)
    }
  }, [gameStatus, updateCount])

  useEffect(() => {
    if (count === FINAL_COUNT) {
      clearInterval(intervalRef.current)
      dispatch(actions.finishGame())
    }
  }, [count, dispatch])

  useEffect(() => {
    if (gameStatus === GAME_STATUS.ONGOING) {
      const shouldStopTimer = modals.length > 0 ? modals[0].stopsTimer ?? false : false
      if (shouldStopTimer && !!intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = 0
      } else if (modals.length === 0 && intervalRef.current === 0) {
        intervalRef.current = setInterval(updateCount, 1000)
      }
    }
  }, [modals, updateCount, gameStatus])

  return (
    <TimerStyled className={className}>
      <TimerValue className={count < 6 ? 'shortTime' : null}>{count}</TimerValue>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
        <path d={draw(6 * count)} fill={count > 5 ? FILL_BLUE : FILL_RED} />
      </svg>
    </TimerStyled>
  )
}

Timer.propTypes = {
  className: PropTypes.string
}

export default Timer
