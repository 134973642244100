import styled from 'styled-components'

const PlayAgainButton = styled.button`
  cursor: pointer;
  width: 224px;
  height: 58px;
  border-radius: 8px;
  background-color: #1d428a;
  border: 1px solid #ffffff;
  font-size: 25px;
  line-height: 25px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: 300;
  font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;

  text-align: center;
  transition: ${({ theme }) => theme.buttons.transition};

  &:hover {
    transform: ${({ theme }) => theme.buttons.transform};
  }
`

export default PlayAgainButton
