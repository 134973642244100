import { SCENARIOS, PROBLEMS } from 'helpers/'

import { ReactComponent as CloseIcon } from './x-button.svg'
import city from './city.jpg'
import waterproblem1 from './waterproblem1.jpg'
import waterproblem2 from './waterproblem2.jpg'
import powerproblem1 from './powerproblem1.jpg'
import powerproblem2 from './powerproblem2.jpg'
import powerproblemfixed from './powerproblem-fixed.jpg'
import waterproblemfixed from './waterproblem-fixed.jpg'
import landingBackground from './landing-background.jpg'
import powerModal from './img-power-problem.jpg'
import pipeModal from './img-pipe-problem.jpg'
import bubbleScenarioPipe from './bubble-scenario-pipe.svg'
import bubbleScenarioPower from './bubble-scenario-power.svg'
import VMWareLogo from './VMWare-logo.svg'
import IconInfo from './icon-info.png'
import CTAJoe from './CTA-joe.svg'
import CTABob from './CTA-bob.svg'
import Coffee from './coffee.svg'
import Lightbulb from './lightbulb.svg'
import Twitter from './twitter.svg'
import Facebook from './facebook.svg'
import Linkedin from './linkedin.svg'
import BubbleCTA1 from './CTA1-bubble.svg'
import BubbleCTA2 from './CTA2-bubble.svg'
import clock from './clock.svg'
import vmwLogoSq from './favicon/259828-vmware-favicon-inbrowser-192x192.png'
import modalMask from './modal-mask.png'
import { ReactComponent as ChevronIcon } from './icon-chevron.svg'
import { ReactComponent as RotationIcon } from './icon-rotation.svg'

export const svg = {
  closeIcon: CloseIcon,
  powerModal,
  pipeModal,
  bubbleScenarioPipe,
  bubbleScenarioPower,
  VMWareLogo,
  CTAJoe,
  CTABob,
  Coffee,
  Lightbulb,
  Twitter,
  Facebook,
  Linkedin,
  BubbleCTA1,
  BubbleCTA2,
  ChevronIcon,
  RotationIcon
}

export const img = {
  city,
  landingBackground,
  background: {
    [SCENARIOS.PIPE]: {
      [PROBLEMS.PROBLEM1]: waterproblem1,
      [PROBLEMS.PROBLEM2]: waterproblem2,
      fixed: waterproblemfixed
    },
    [SCENARIOS.POWER]: {
      [PROBLEMS.PROBLEM1]: powerproblem1,
      [PROBLEMS.PROBLEM2]: powerproblem2,
      fixed: powerproblemfixed
    }
  },
  IconInfo,
  clock,
  vmwLogoSq,
  modalMask
}
