import styled from 'styled-components'

const DownloadButton = styled.div`
  width: 325px;
  height: 59px;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.downloadButton.background};
  border: 1px solid ${({ theme }) => theme.downloadButton.border};
  font-size: 24px;
  color: ${({ theme }) => theme.downloadButton.color};
  font-weight: 300;
  font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${({ theme }) => theme.buttons.transition};

  &:hover {
    transform: ${({ theme }) => theme.buttons.transform};
  }
`

export default DownloadButton
