import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { KEYS, GAME_STATUS, uuidv4 } from 'helpers/'
import * as actions from 'actions/'
import { ModalResult } from 'components/'
import { useGameComplete } from 'hooks/'

const GameController = () => {
  const dispatch = useDispatch()
  const gameStatus = useSelector((state) => state.game?.[KEYS.GAME_STATUS].value)
  const scenarioComplete = useGameComplete()

  useEffect(() => {
    if (scenarioComplete) {
      dispatch(actions.finishGame())
    }
  }, [scenarioComplete, dispatch])

  useEffect(() => {
    if (gameStatus === GAME_STATUS.FINISHED) {
      const modalId = uuidv4()
      dispatch(actions.openModal(modalId, <ModalResult scenario={scenarioComplete} />), true)
    }
  }, [gameStatus, scenarioComplete, dispatch])

  return null
}

export default GameController
