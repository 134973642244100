import styled from 'styled-components'

export const ModalsStackStyled = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.z.modal};
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ position }) => `rgba(0, 0, 0, ${0.3 + 0.2 * position})`};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme, position }) => theme.z.modal + position};
`

export const Modal = styled.div`
  display: block;
  position: absolute;
  border-radius: ${({ theme }) => theme.modal.borderRadius};
  background-color: ${({ theme }) => theme.modal.background};
`
