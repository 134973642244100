import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import * as actions from 'actions/'
import { ModalResultStyled, StyledButtonLink } from './styles'
import { svg } from 'styles/assets'
import { DownloadButton, PlayAgainButton } from 'components/'
import { SCENARIOS } from 'helpers/'

const URL_TWITTER =
  'https://twitter.com/intent/tweet?url=https://www.minutetofixit.com/&text=I just played Minute to Fix It! Can you beat the clock? ⏰ Play now to find out!&hashtags=vRealize,MinuteToFixIt'

const URL_FACEBOOK =
  'https://www.facebook.com/dialog/share?app_id=443342876763820&display=popup&href=https://www.minutetofixit.com'
const URL_LINKEDIN =
  'https://www.linkedin.com/shareArticle?mini=true&url=https://www.minutetofixit.com/'

const MODAL_CONFIG = {
  WON: {
    title: 'Congrats!',
    downloadText:
      'Want to be an even better root-cause hunter, identifying root causes up to 50% faster?',
    playAgainText: 'See if you can fix the other issue!',
    againButtonText: 'PLAY AGAIN',
    [SCENARIOS.PIPE]: {
      subtitle:
        'You identified the root cause and saved Data Centerville from a very serious caffeine shortage.',
      person: svg.CTAJoe,
      bubble: svg.BubbleCTA1,
      bubbleText: '“Yay! I have my morning coffee.”',
      object: svg.Coffee
    },
    [SCENARIOS.POWER]: {
      subtitle: 'You identified the root cause and saved Data Centerville from a dark future.',
      person: svg.CTABob,
      bubble: svg.BubbleCTA2,
      bubbleText: '“Great! The power’s back!”',
      object: svg.Lightbulb
    }
  },
  LOST: {
    title: 'Time’s up!',
    subtitle: 'Better luck next time...',
    downloadText:
      'But we won’t send you home empty-handed. See how you can identify root causes up to 50% faster.',
    playAgainText: 'Try to solve the problem again or see if you can fix the other issue!',
    againButtonText: 'TRY AGAIN'
  }
}

const TAG_PLAY_AGAIN = 'Play Again'
const TAG_DOWNLOAD = 'Download Guide'
const TAG_SHARE_FB = 'Facebook Share'
const TAG_SHARE_TW = 'Twitter Share'
const TAG_SHARE_LI = 'LinkedIn Share'

const handleTagCall = (value) => {
  if (value === TAG_PLAY_AGAIN) {
    window.gtag('event', 'click', { category: 'Gameplay', label: value })
  } else if (value === TAG_DOWNLOAD) {
    window.gtag('event', 'click', { category: 'Resource', label: value })
  } else {
    window.gtag('event', 'click', { category: 'Share', label: value })
  }
}

const ButtonLink = ({ tag, url, icon, iconClassName }) => {
  const handleClick = useCallback(() => {
    handleTagCall(tag)
    window.open(url, '_blank', 'noopener,noreferrer')
  }, [tag, url])

  return (
    <StyledButtonLink onClick={handleClick}>
      <img src={icon} className={iconClassName} />
    </StyledButtonLink>
  )
}

ButtonLink.propTypes = {
  tag: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.string,
  iconClassName: PropTypes.string
}

const ModalResult = ({ scenario }) => {
  const dispatch = useDispatch()

  const modalDetails = useMemo(() => {
    if (scenario) {
      return {
        title: MODAL_CONFIG.WON.title,
        downloadText: MODAL_CONFIG.WON.downloadText,
        playAgainText: MODAL_CONFIG.WON.playAgainText,
        againButtonText: MODAL_CONFIG.WON.againButtonText,
        subtitle: MODAL_CONFIG.WON[scenario].subtitle,
        person: MODAL_CONFIG.WON[scenario].person,
        bubble: MODAL_CONFIG.WON[scenario].bubble,
        bubbleText: MODAL_CONFIG.WON[scenario].bubbleText,
        object: MODAL_CONFIG.WON[scenario].object
      }
    } else {
      return {
        title: MODAL_CONFIG.LOST.title,
        subtitle: MODAL_CONFIG.LOST.subtitle,
        downloadText: MODAL_CONFIG.LOST.downloadText,
        playAgainText: MODAL_CONFIG.LOST.playAgainText,
        againButtonText: MODAL_CONFIG.LOST.againButtonText
      }
    }
  }, [scenario])

  const handlePlayAgain = useCallback(() => {
    handleTagCall(TAG_PLAY_AGAIN)
    dispatch(actions.resetGame())
  }, [dispatch])

  const handleDownload = useCallback(() => {
    handleTagCall(TAG_DOWNLOAD)
  }, [])

  return (
    <ModalResultStyled>
      <div className={scenario ? 'top-blue-container-won' : 'top-blue-container-lost'}>
        <div>
          <p className="title">{modalDetails.title}</p>
          <p
            className={
              scenario
                ? scenario === SCENARIOS.POWER
                  ? 'subtitle power-subtitle'
                  : 'subtitle pipe-subtitle'
                : 'subtitle subtitle-lost'
            }
          >
            {modalDetails.subtitle}
          </p>
        </div>
        <img
          src={modalDetails.object}
          className={scenario ? (scenario === SCENARIOS.POWER ? 'lightbulb' : 'coffee') : null}
        ></img>
        <img
          src={modalDetails.person}
          className={
            scenario ? (scenario === SCENARIOS.POWER ? 'power-person' : 'pipe-person') : null
          }
        ></img>
        <div
          className={
            scenario
              ? scenario === SCENARIOS.POWER
                ? 'power-bubble-container'
                : 'pipe-bubble-container'
              : null
          }
        >
          <img
            src={modalDetails.bubble}
            className={
              scenario ? (scenario === SCENARIOS.POWER ? 'power-bubble' : 'pipe-bubble') : null
            }
          ></img>
          <p
            className={
              scenario
                ? scenario === SCENARIOS.POWER
                  ? 'power-bubble-text'
                  : 'pipe-bubble-text'
                : null
            }
          >
            {modalDetails.bubbleText}
          </p>
        </div>
      </div>
      <div className="bottom-lightblue-container">
        <div className="download-container">
          <p className={scenario ? 'download-text-won' : 'download-text-lost'}>
            {modalDetails.downloadText}
          </p>
          <a href="https://www.vmware.com/learn/773508_REG.html" target="_blank" rel="noreferrer">
            <DownloadButton className="download-btn" onClick={handleDownload}>
              DOWNLOAD GUIDE
            </DownloadButton>
          </a>
        </div>
        <div className="again-container">
          <p className={scenario ? 'again-text-won' : 'again-text-lost'}>
            {modalDetails.playAgainText}
          </p>
          <PlayAgainButton
            onClick={handlePlayAgain}
            className={scenario ? 'again-btn-won' : 'again-btn-lost'}
          >
            {modalDetails.againButtonText}
          </PlayAgainButton>
        </div>
      </div>
      <div className="footer-container">
        <p className="footer-text">
          Wonder if your friends and coworkers can beat the clock? Why not invite them to try?
        </p>
        <div className="footer-icons-container">
          <ButtonLink
            url={URL_TWITTER}
            tag={TAG_SHARE_TW}
            icon={svg.Twitter}
            iconClassName="twitter-icon"
          />
          <ButtonLink
            url={URL_FACEBOOK}
            tag={TAG_SHARE_FB}
            icon={svg.Facebook}
            iconClassName="facebook-icon"
          />
          <ButtonLink
            url={URL_LINKEDIN}
            tag={TAG_SHARE_LI}
            icon={svg.Linkedin}
            iconClassName="linkedin-icon"
          />
        </div>
      </div>
    </ModalResultStyled>
  )
}

ModalResult.propTypes = {
  scenario: PropTypes.string
}

export default ModalResult
