import React, { useEffect } from 'react'
import { TitleStyled, SubtitleStyled, LandingPageStyled, LinkEnter, ButtonEnter } from './styles'
import { VMWareLogo } from 'components/'

export const LandingPage = () => {
  useEffect(() => {
    function handleResize() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <LandingPageStyled>
      <TitleStyled>
        vRealize True Visibility Suite: <br />
        <span>Minute to Fix It</span>
      </TitleStyled>
      <SubtitleStyled>
        Welcome to Data Centerville, a place where there’s a fire drill around every corner...
        <br />
        <br />
        Use your wits to determine the root cause of a problem in a minute and win!
      </SubtitleStyled>
      <LinkEnter to="/game" className="link">
        <ButtonEnter>ENTER</ButtonEnter>
      </LinkEnter>
      <VMWareLogo />
    </LandingPageStyled>
  )
}
