import styled from 'styled-components'

const StartButton = styled.button`
  cursor: pointer;
  width: 313px;
  height: 94px;
  line-height: 94px;
  border-radius: 5px;
  background-image: linear-gradient(45deg, #0091da 0%, #00c1d5 100%);
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 2px;
  color: #fbfbfc;
  font-weight: 300;
  font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;
  text-align: center;
  transition: ${({ theme }) => theme.buttons.transition};

  &:hover {
    transform: ${({ theme }) => theme.buttons.transform};
  }

  @media (max-width: 900px) {
    width: 245px;
    height: 47px;
    font-size: 16px;
    line-height: 16px;
  }
`

export default StartButton
