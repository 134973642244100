import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import { svg } from 'styles/assets/img'
import { Container } from './styles'

const DeviceRotation = ({ dimensions }) => {
  const showMessage = useMemo(() => {
    if (dimensions.width && dimensions.height) {
      const ratio = dimensions.width / dimensions.height
      if (ratio > 1.4) return true
    }
    return false
  }, [dimensions])

  useEffect(() => {
    function handleDeviceOrientation() {
      location.reload()
    }

    window.addEventListener('orientationchange', handleDeviceOrientation)

    return () => window.removeEventListener('orientationchange', handleDeviceOrientation)
  }, [])

  if (showMessage) {
    return (
      <Container>
        <svg.RotationIcon />
        <div className="message">
          Please turn your device. This site is optimized for portrait orientation on mobile
          devices.
        </div>
      </Container>
    )
  }
  return null
}

DeviceRotation.propTypes = {
  dimensions: PropTypes.object
}

export default React.memo(DeviceRotation)
