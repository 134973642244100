import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import * as actions from 'actions/'
import { SCENARIOS, PROBLEMS } from 'helpers/'
import { ButtonX } from 'components/'
import { ModalActionStyled } from './styles'
import { svg, img } from 'styles/assets'

const MODAL_CONFIG = {
  [SCENARIOS.PIPE]: {
    [PROBLEMS.PROBLEM1]: {
      text: 'Find the root cause of the problem so Joe can make his coffee!',
      bubbleText: '"Oh no! How will I make my coffee now?!"',
      bubbleTextStyle: 'bubble-text pipe-one'
    },
    [PROBLEMS.PROBLEM2]: {
      text:
        'You cleared the blockage! But the water still isn’t flowing. You need to go farther upstream.',
      bubbleText: ' “There’s no    water yet!”',
      bubbleTextStyle: 'bubble-text-center pipe-two'
    },
    scenarioImg: svg.pipeModal,
    bubbleScenario: 'arrow-down-pipe',
    bubbleStyle: 'bubble-pipe-scenario',
    imgClass: 'scenario-img-pipe'
  },
  [SCENARIOS.POWER]: {
    [PROBLEMS.PROBLEM1]: {
      text: 'Turn the lights back on by discovering the root cause of the problem!',
      bubbleText: '“Ack! What happened to the power?”',
      bubbleTextStyle: 'bubble-text power-one'
    },
    [PROBLEMS.PROBLEM2]: {
      text: 'You fixed the power line! But there’s still something wrong...',
      bubbleText: '“No power yet!”',
      bubbleTextStyle: 'bubble-text-center power-two'
    },
    scenarioImg: svg.powerModal,
    bubbleScenario: 'arrow-down-power',
    bubbleStyle: 'bubble-power-scenario',
    imgClass: 'scenario-img-power'
  }
}

const ModalAction = ({ id, scenario, problem }) => {
  const dispatch = useDispatch()
  const text = useMemo(() => MODAL_CONFIG[scenario]?.[problem]?.text ?? '', [scenario, problem])
  const bubbleText = useMemo(() => MODAL_CONFIG[scenario]?.[problem]?.bubbleText ?? '', [
    scenario,
    problem
  ])
  const bubbleTextStyle = useMemo(() => MODAL_CONFIG[scenario]?.[problem]?.bubbleTextStyle ?? '', [
    scenario,
    problem
  ])
  const scenarioImg = useMemo(() => MODAL_CONFIG[scenario]?.scenarioImg ?? '', [scenario])
  const bubbleScenario = useMemo(() => MODAL_CONFIG[scenario]?.bubbleScenario ?? '', [scenario])
  const bubbleStyle = useMemo(() => MODAL_CONFIG[scenario]?.bubbleStyle ?? '', [scenario])
  const imgClass = useMemo(() => MODAL_CONFIG[scenario]?.imgClass ?? '', [scenario])

  const handleClose = useCallback(() => {
    dispatch(actions.closeModal(id))
    dispatch(actions.showProblem(scenario))
  }, [dispatch, id, scenario])

  return (
    <ModalActionStyled>
      <ButtonX className="button-x" onClick={handleClose}>
        X
      </ButtonX>
      <div className="scenario-img-container">
        <img src={scenarioImg} className={imgClass}></img>
        <img src={img.modalMask} className="img-mask-desktop"></img>
      </div>
      <div className={bubbleStyle}>
        <div className={bubbleScenario}></div>
        <p className={bubbleTextStyle}>{bubbleText}</p>
      </div>
      <p className="instructions-text">{text}</p>
    </ModalActionStyled>
  )
}

ModalAction.propTypes = {
  id: PropTypes.string.isRequired,
  scenario: PropTypes.string.isRequired,
  problem: PropTypes.string.isRequired
}

export default ModalAction
