import styled from 'styled-components'

export const Container = styled.div`
  canvas,
  .canvas-container {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
`
export const StyledBackgroundCanvas = styled.canvas`
  z-index: ${({ theme }) => theme.z.xray};
  position: absolute;
`

export const StyledCanvas = styled.canvas`
  z-index: ${({ theme }) => theme.z.city};
`
