import styled from 'styled-components'

const ButtonX = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 21px;
  background-color: ${({ theme }) => theme.buttonX.background};
  border: 2px solid ${({ theme }) => theme.buttonX.border};
  cursor: pointer;
  font-size: 25px;
  line-height: 1px;
  color: ${({ theme }) => theme.buttonX.color};
`

export default ButtonX
