import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { Overlay } from 'components/'
import { THEME } from 'styles/themes/light'
import { GameInfoStyled, IconInfoStyled } from './styles'

const InfoGame = ({ isShowing, isMobile, onToggleShowing }) => {
  const handleClick = useCallback(() => {
    onToggleShowing()
  }, [onToggleShowing])

  return (
    <>
      {isShowing && (
        <Overlay onClick={handleClick} className="overlay" $zIndex={THEME.z.instructions} />
      )}
      <GameInfoStyled showinfo={isShowing} isMobile={isMobile}>
        {isShowing && (
          <div className="instructions-container">
            {isMobile ? (
              <ul>
                <li>When you’re ready to play, click “Start.”</li>
                <li>
                  Alerts for two issues will appear. Pick the one you want to investigate. This
                  starts the clock.
                </li>
                <li>Prompts will appear, providing you with details.</li>
                <li>To navigate around Data Centerville, use two fingers to swipe.</li>
                <li>
                  To explore what’s under the surface with your magnifying glass, use one finger.
                </li>
                <li>Click the wrench to repair problems you discover.</li>
                <li>Correctly identify the root cause and fix the issue in 60 seconds to win!</li>
                <li>
                  Play again if you’d like! (If you lose, you can try to solve the same issue or
                  pick the other issue.)
                </li>
              </ul>
            ) : (
              <ul>
                <li>When you’re ready to play, click “Start.”</li>
                <li>
                  Alerts for two issues will appear. Pick the one you want to investigate. This
                  starts the clock.
                </li>
                <li>Prompts will appear, providing you with details. </li>
                <li>Use the magnifying glass to navigate.</li>
                <li>Click the wrench to repair problems you discover.</li>
                <li>Correctly identify the root cause and fix the issue in 60 seconds to win!</li>
                <li>
                  Play again if you’d like! (If you lose, you can try to solve the same issue or
                  pick the other issue.)
                </li>
              </ul>
            )}
          </div>
        )}
        <div className="arrow-down"></div>
        <IconInfoStyled onClick={handleClick} />
      </GameInfoStyled>
    </>
  )
}

InfoGame.propTypes = {
  isShowing: PropTypes.bool,
  isMobile: PropTypes.bool,
  onToggleShowing: PropTypes.func
}

export default React.memo(InfoGame)
