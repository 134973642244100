import React from 'react'
import { useSelector } from 'react-redux'
import { createPortal } from 'react-dom'

import { KEYS } from 'helpers/'
import { ModalsStackStyled, Overlay, Modal } from './styles'

const ModalsStack = (props) => {
  const modals = useSelector((state) => state.modals[KEYS.MODALS])

  return createPortal(
    <ModalsStackStyled>
      {modals.map((n, i) => {
        return (
          <Overlay key={`modal-${i}`} position={i}>
            <Modal>{n.content}</Modal>
          </Overlay>
        )
      })}
    </ModalsStackStyled>,
    document.querySelector('#modal-stack')
  )
}

export default ModalsStack
