import styled from 'styled-components'

const Button = styled.button`
  cursor: pointer;
  width: 200px;
  height: 50px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.button.background};
  letter-spacing: 1px;
  color: ${({ theme }) => theme.button.color};
  outline: none;
  border: none;
  font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  transition: ${({ theme }) => theme.buttons.transition};

  &:hover {
    transform: ${({ theme }) => theme.buttons.transform};
  }

  @media (max-width: 900px) {
    font-size: 16px;
  }
`

export default Button
