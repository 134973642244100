import { fabric } from 'fabric'

import { SCENARIOS, PROBLEMS } from 'helpers/'

export const TARGET_ID = 'target_rectangle_'

const TARGET_POSITION = {
  [SCENARIOS.PIPE]: {
    [PROBLEMS.PROBLEM1]: {
      top: 650,
      left: 910
    },
    [PROBLEMS.PROBLEM2]: {
      top: 170,
      left: 190
    }
  },
  [SCENARIOS.POWER]: {
    [PROBLEMS.PROBLEM1]: {
      top: 410,
      left: 660
    },
    [PROBLEMS.PROBLEM2]: {
      top: 490,
      left: 1480
    }
  }
}

const createTarget = (scenario, problem) => {
  const position = TARGET_POSITION[scenario][problem]
  const hoverRect = new fabric.Rect({
    width: 110,
    height: 110,
    fill: 'rgba(0,0,0,0)',
    top: position.top,
    left: position.left
  })

  hoverRect.hoverCursor = 'pointer'
  hoverRect.id = TARGET_ID + problem

  return hoverRect
}

export const TARGETS = {
  [SCENARIOS.PIPE]: {
    [PROBLEMS.PROBLEM1]: createTarget(SCENARIOS.PIPE, PROBLEMS.PROBLEM1),
    [PROBLEMS.PROBLEM2]: createTarget(SCENARIOS.PIPE, PROBLEMS.PROBLEM2)
  },
  [SCENARIOS.POWER]: {
    [PROBLEMS.PROBLEM1]: createTarget(SCENARIOS.POWER, PROBLEMS.PROBLEM1),
    [PROBLEMS.PROBLEM2]: createTarget(SCENARIOS.POWER, PROBLEMS.PROBLEM2)
  }
}
