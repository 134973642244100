import React, { useState, useCallback, useLayoutEffect, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'

import { GAME_STATUS, SCENARIOS, uuidv4 } from 'helpers/'
import { StyledCanvas, StyledBackgroundCanvas, Container } from './styles'
import {
  CANVAS_IMAGE_WIDTH,
  CANVAS_IMAGE_HEIGHT,
  MAGNIFYING_GLASS_BORDER_RADIUS,
  magGlassContent,
  magGlass,
  capPosition,
  dialogPipe,
  dialogPower,
  DIALOG_PIPE,
  DIALOG_POWER
} from './utils'
import { TARGETS, TARGET_ID } from './targets'
import { useGameRestarts } from 'hooks/'

const DEBUG = process.env.REACT_APP_IS_DEV || process.env.REACT_APP_IS_TESTING
const DESKTOP_ZOOM_COEFFICIENT = 0.2

const Canvas = ({
  width,
  height,
  loading,
  isMobile,
  gameStatus,
  scenario,
  problem,
  shouldShowScenarios,
  onSelectScenario,
  onSolveProblem,
  onGameStart,
  onCanvasLoad
}) => {
  const [canvasId] = useState(`canvas-${uuidv4()}`)
  const [backgroundCanvasId] = useState(`canvas-${uuidv4()}`)
  const [canvas, setCanvas] = useState(null)
  const [backgroundCanvas, setBackgroundCanvas] = useState(null)

  const [shouldRestart, countRestart] = useGameRestarts()

  const isDraggingRef = useRef()
  const isUsingMagGlass = useRef()
  const isMagGlassLocked = useRef()
  isUsingMagGlass.current = useMemo(() => !!scenario, [scenario])
  const lastXRef = useRef()
  const lastYRef = useRef()

  const scenarioImg = useMemo(() => {
    if (!loading && scenario) {
      return new fabric.Image(document.getElementById(`problem-fixed-${scenario}`), {
        left: 0,
        top: 0,
        selectable: false
      })
    }

    return undefined
  }, [loading, scenario])

  const problemImg = useMemo(() => {
    if (!loading && scenario && problem) {
      return new fabric.Image(document.getElementById(`problem-${scenario}-${problem}`), {
        left: 0,
        top: 0,
        selectable: false
      })
    }

    return undefined
  }, [loading, scenario, problem])

  const minZoom = useMemo(
    () =>
      Math.max(width / CANVAS_IMAGE_WIDTH, height / CANVAS_IMAGE_HEIGHT) +
      (!isMobile ? DESKTOP_ZOOM_COEFFICIENT : 0),
    [width, height, isMobile]
  )

  const initialViewportTransform = useMemo(
    () => [
      CANVAS_IMAGE_WIDTH * minZoom > width ? (width - CANVAS_IMAGE_WIDTH * minZoom) / 2 : 0,
      CANVAS_IMAGE_HEIGHT * minZoom > height ? (height - CANVAS_IMAGE_HEIGHT * minZoom) / 2 : 0
    ],
    [width, height, minZoom]
  )

  const handleCanvasSetup = useCallback(() => {
    const currentCanvas = new fabric.Canvas(canvasId)
    currentCanvas.preserveObjectStacking = true
    currentCanvas.selection = false
    currentCanvas.setDimensions({ width: width, height: height })
    currentCanvas.hoverCursor = 'all-scroll'

    currentCanvas.skipOffscreen = true
    currentCanvas.allowTouchScrolling = false

    const currentBackgroundCanvas = new fabric.StaticCanvas(backgroundCanvasId)
    currentBackgroundCanvas.setDimensions({ width: width, height: height })
    currentBackgroundCanvas.skipOffscreen = true
    currentBackgroundCanvas.allowTouchScrolling = false

    fabric.Object.prototype.selectable = false
    currentCanvas.setZoom(minZoom)
    currentBackgroundCanvas.setZoom(minZoom)
    currentCanvas.viewportTransform[4] = initialViewportTransform[0]
    currentCanvas.viewportTransform[5] = initialViewportTransform[1]
    currentBackgroundCanvas.viewportTransform[4] = initialViewportTransform[0]
    currentBackgroundCanvas.viewportTransform[5] = initialViewportTransform[1]

    lastXRef.current = -initialViewportTransform[0]
    lastYRef.current = -initialViewportTransform[1]
    isMagGlassLocked.current = false

    currentCanvas.calcViewportBoundaries()
    onCanvasLoad(currentCanvas.vptCoords)

    currentCanvas.on('mouse:down', (opt) => {
      if (!isMobile) {
        const evt = opt.e
        isDraggingRef.current = true
        lastXRef.current = evt.clientX
        lastYRef.current = evt.clientY
      } else {
        const evt = opt.e.touches
        if (evt.length === 1) {
          lastXRef.current = evt[0].clientX
          lastYRef.current = evt[0].clientY
        }
      }
    })

    if (!isMobile) {
      currentCanvas.on('mouse:move', (opt) => {
        if (isDraggingRef.current) {
          const evt = opt.e
          const vpt = currentCanvas.viewportTransform
          const vptBg = currentBackgroundCanvas.viewportTransform
          const diffX = evt.clientX - lastXRef.current
          const diffY = evt.clientY - lastYRef.current

          const newValues = capPosition(
            vpt[4],
            vpt[5],
            currentCanvas.getZoom(),
            diffX,
            diffY,
            width,
            height
          )
          vpt[4] = newValues[0]
          vpt[5] = newValues[1]
          vptBg[4] = newValues[0]
          vptBg[5] = newValues[1]

          currentCanvas.requestRenderAll()
          currentBackgroundCanvas.requestRenderAll()
          lastXRef.current = evt.clientX
          lastYRef.current = evt.clientY
        } else {
          if (isUsingMagGlass.current && !isMagGlassLocked.current) {
            const vpt = currentCanvas.viewportTransform
            magGlass
              .set({
                left:
                  (opt.e.clientX - vpt[4] - MAGNIFYING_GLASS_BORDER_RADIUS) /
                  currentCanvas.getZoom(),
                top:
                  (opt.e.clientY - vpt[5] - MAGNIFYING_GLASS_BORDER_RADIUS) /
                  currentCanvas.getZoom()
              })
              .setCoords()
            currentCanvas.requestRenderAll()
          }
        }
      })
    } else {
      currentCanvas.on('mouse:move', (opt) => {
        const evt = opt.e.touches
        if (evt?.length === 2 || (DEBUG && opt.e.shiftKey)) {
          let avgX = 0
          let avgY = 0
          if (DEBUG && opt.e.shiftKey) {
            avgX = evt[0].clientX
            avgY = evt[0].clientY
          } else {
            avgX = (evt[0].clientX + evt[1].clientX) / 2
            avgY = (evt[0].clientY + evt[1].clientY) / 2
          }

          const vpt = currentCanvas.viewportTransform
          const vptBg = currentBackgroundCanvas.viewportTransform
          const diffX = avgX - lastXRef.current ?? 0
          const diffY = avgY - lastYRef.current ?? 0
          const newValues = capPosition(
            vpt[4],
            vpt[5],
            currentCanvas.getZoom(),
            diffX,
            diffY,
            width,
            height
          )
          vpt[4] = newValues[0]
          vpt[5] = newValues[1]
          vptBg[4] = newValues[0]
          vptBg[5] = newValues[1]

          currentCanvas.requestRenderAll()
          currentBackgroundCanvas.requestRenderAll()
          lastXRef.current = avgX
          lastYRef.current = avgY
        } else if (evt?.length === 1 && isUsingMagGlass.current && !isMagGlassLocked.current) {
          const vpt = currentCanvas.viewportTransform
          magGlass
            .set({
              left:
                (evt[0].clientX - vpt[4] - MAGNIFYING_GLASS_BORDER_RADIUS) /
                currentCanvas.getZoom(),
              top:
                (evt[0].clientY - vpt[5] - MAGNIFYING_GLASS_BORDER_RADIUS) / currentCanvas.getZoom()
            })
            .setCoords()
          currentCanvas.requestRenderAll()
        }
      })
    }

    currentCanvas.on('mouse:up', (opt) => {
      currentCanvas.setViewportTransform(currentCanvas.viewportTransform)
      currentBackgroundCanvas.setViewportTransform(currentBackgroundCanvas.viewportTransform)
      if (!isMobile) {
        isDraggingRef.current = false
      }
    })

    currentCanvas.on('mouse:up', (opt) => {
      if (opt.target?.id === DIALOG_PIPE) {
        onSelectScenario(SCENARIOS.PIPE)
        onGameStart()
      }
      if (opt.target?.id === DIALOG_POWER) {
        onSelectScenario(SCENARIOS.POWER)
        onGameStart()
      }
    })

    setCanvas(currentCanvas)
    setBackgroundCanvas(currentBackgroundCanvas)
  }, [
    canvasId,
    width,
    height,
    isMobile,
    minZoom,
    initialViewportTransform,
    backgroundCanvasId,
    onSelectScenario,
    onGameStart,
    onCanvasLoad
  ])

  useLayoutEffect(() => {
    handleCanvasSetup()
  }, [handleCanvasSetup])

  const handleCanvasReset = useCallback(() => {
    if (canvas && backgroundCanvas) {
      canvas.viewportTransform[4] = initialViewportTransform[0]
      canvas.viewportTransform[5] = initialViewportTransform[1]
      backgroundCanvas.viewportTransform[4] = initialViewportTransform[0]
      backgroundCanvas.viewportTransform[5] = initialViewportTransform[1]

      lastXRef.current = initialViewportTransform[0]
      lastYRef.current = initialViewportTransform[1]
    }
  }, [canvas, backgroundCanvas, initialViewportTransform])

  useEffect(() => {
    if (shouldRestart) {
      handleCanvasReset()
      countRestart()
    }
  }, [shouldRestart, handleCanvasReset, countRestart])

  useEffect(() => {
    if (!loading && canvas) {
      const cityInstance = new fabric.Image(document.getElementById('city-map'), {
        left: 0,
        top: 0,
        selectable: false
      })
      canvas.add(cityInstance)
      cityInstance.sendToBack()
    }
  }, [loading, canvas])

  useEffect(() => {
    if (!loading && backgroundCanvas && scenarioImg) {
      backgroundCanvas.clear().add(scenarioImg)
    }
  }, [loading, backgroundCanvas, scenarioImg])

  useEffect(() => {
    if (!loading && backgroundCanvas && problemImg) {
      backgroundCanvas.add(problemImg)
    }
  }, [loading, backgroundCanvas, problemImg])

  useEffect(() => {
    if (!loading && canvas) {
      if (gameStatus === GAME_STATUS.NEW && shouldShowScenarios && !scenario) {
        canvas.add(dialogPipe)
        canvas.add(dialogPower)
      } else {
        canvas.remove(dialogPipe)
        canvas.remove(dialogPower)
      }
    }
  }, [loading, canvas, scenario, gameStatus, shouldShowScenarios])

  const eventHandler = useCallback(
    (opt) => {
      if (opt.target?.id === TARGET_ID + problem) {
        isMagGlassLocked.current = true
        problemImg.animate('opacity', '0', {
          onChange: backgroundCanvas.renderAll.bind(backgroundCanvas),
          duration: 1000,
          easing: fabric.util.ease.easeInOutQuad,
          onComplete: () => {
            canvas.remove(TARGETS[scenario][problem])
            canvas.remove(problemImg)
            canvas.off('mouse:up', eventHandler)
            setTimeout(() => {
              isMagGlassLocked.current = false
              onSolveProblem(problem)
            }, 100)
          }
        })
      }
    },
    [canvas, backgroundCanvas, problem, scenario, onSolveProblem, problemImg]
  )

  useEffect(() => {
    if (!loading && canvas) {
      if (scenario && problem) {
        canvas.add(magGlassContent)
        canvas.add(magGlass)
        canvas.add(TARGETS[scenario][problem])
        canvas.on('mouse:up', eventHandler)
      } else {
        canvas.remove(magGlassContent)
        canvas.remove(magGlass)
      }
    }
  }, [loading, canvas, scenario, problem, eventHandler])

  return (
    <Container>
      <StyledBackgroundCanvas id={backgroundCanvasId} />
      <StyledCanvas id={canvasId} />
    </Container>
  )
}

Canvas.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  loading: PropTypes.bool,
  isMobile: PropTypes.bool,
  gameStatus: PropTypes.string,
  scenario: PropTypes.string,
  problem: PropTypes.string,
  shouldShowScenarios: PropTypes.bool,
  onSelectScenario: PropTypes.func,
  onSolveProblem: PropTypes.func,
  onGameStart: PropTypes.func,
  onCanvasLoad: PropTypes.func
}

export default Canvas
