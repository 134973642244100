import styled from 'styled-components'

const VMWareLogo = styled.div`
  width: 142px;
  height: 40px;
  border-radius: 20px;
  background: #1d428a url(${({ theme }) => theme.vmware.svg});
  z-index: 10;
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  @media (max-width: 900px) {
    width: 84px;
    height: 22px;
    background-size: 65%;
    bottom: 3%;
  }
}
`

export default VMWareLogo
