import styled from 'styled-components'
import { img } from '../../../styles/assets/img'

export const ModalActionStyled = styled.div`
  width: 908px;
  height: 508px;
  background-color: ${({ theme }) => theme.modal.background};
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  z-index: 1000;

  .scenario-img-container {
    overflow: hidden;
    position: absolute;
    width: 54%;
    height: 100%;
    background-color: white;
  }

  .scenario-img-pipe {
    position: absolute;
    width: 553px;
  }

  .scenario-img-power {
    position: absolute;
    left: -4%;
    width: 532px;
  }

  // mask for curves image on desktop
  .img-mask-desktop {
    position: absolute;
    left: -17px;
    height: 100%;
    background: url(${img.modalMask});
  }

  .instructions-text {
    position: relative;
    left: 58%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 350px;
    height: 506px;
    font-size: 42px;
    line-height: 50px;
    color: ${({ theme }) => theme.modal.text};
    font-weight: 300;
    font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;
  }

  .bubble-pipe-scenario {
    position: absolute;
    height: 100px;
    width: 150px;
    left: 14%;
    top: 6%;
    // background-color: ${({ theme }) => theme.bubble.pipe.background};
  }

  .bubble-power-scenario {
    position: absolute;
    height: 70px;
    width: 200px;
    left: 24%;
    top: 16%;
    // background-color: ${({ theme }) => theme.bubble.power.background};
  }

  .bubble-img {
    position: absolute;
  }

  .arrow-down-pipe,
  .arrow-down-power {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    position: absolute;
    bottom: -17px;
  }

  .arrow-down-pipe {
    border-top: 20px solid ${({ theme }) => theme.bubble.pipe.background};
    left: 26px;
  }

  .arrow-down-power {
    border-top: 20px solid ${({ theme }) => theme.bubble.power.background};
    right: 13px;
  }

  .bubble-text,
  .bubble-text-center {
    z-index: 1;
    margin-top: -68px;
    width: 85%;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.bubble.text};
    font-weight: 300;
    font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;
    text-indent: -5pt;
    position: relative;
    right: 0;
    margin: 0 auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .bubble-text {
    left: 8px;
    text-align: left;
  }

  .pipe-one {
    background-color: ${({ theme }) => theme.bubble.pipe.background};
    padding: 15px;
    padding-left: 22px;
    margin-top: 4px;
    margin-left: -12px;
  }

  .pipe-two {
    background-color: ${({ theme }) => theme.bubble.pipe.background};
    padding: 15px;
    padding-left: 17px;
    margin-top: 14px;
    margin-left: -5px;
  }

  .power-one {
    background-color: ${({ theme }) => theme.bubble.power.background};
    padding: 17px;
    padding-left: 26px;
    margin-top: -3px;
    margin-left: -8px;
  }

  .power-two {
    background-color: ${({ theme }) => theme.bubble.power.background};
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 5px;
    margin-left: 38px;
    padding-left: 3px;
  }

  .bubble-text-center {
    left: 0px;
    text-align: center;
  }

  .button-x {
    position: absolute;
    right: -19px;
    top: -19px;
  }

  > .close-container {
    text-align: right;
    margin: -10px -10px 10px 0;
    cursor: pointer;

    svg {
      height: 20px;
      width: 20px;
      fill: ${({ theme }) => theme.modal.closeIconColor};
    }
  }

  @media (max-width: 950px) {
    width: 309px;
    height: 391px;
    display: flex;
    flex-direction: column-reverse;
    padding: 10px 0;

    .scenario-img-container {
      position: relative;
      overflow: hidden;
      width: 289px;
      height: 235px;
      margin: 0 auto;
      border-radius: inherit;
    }

    .scenario-img-pipe {
      width: 289px;
    }

    .scenario-img-power {
      top: -10%;
      width: 343px;
      left: -16%;
    }

    .img-mask-desktop {
      display: none;
    }

    .instructions-text {
      width: 254px;
      height: 92px;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      left: 0;
      top: -23px;
      margin: 0 auto;
    }

    .bubble-pipe-scenario,
    .bubble-power-scenario {
      height: 50px;
    }

    .bubble-pipe-scenario {
      width: 100px;
      left: 8%;
      top: 44%;
    }

    .bubble-power-scenario {
      width: 136px;
      left: 36%;
      top: 46%;
    }

    .arrow-down-pipe,
    .arrow-down-power {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    .arrow-down-pipe {
      left: 60px;
      border-top: 10px solid ${({ theme }) => theme.bubble.pipe.background};
      bottom: -8px;
    }

    .arrow-down-power {
      border-top: 10px solid ${({ theme }) => theme.bubble.power.background};
      bottom: -9px;
    }

    .bubble-text,
    .bubble-text-center {
      font-size: 10.5px;
      line-height: 12px;
      text-indent: -3pt;
    }

    .bubble-text {
      left: 3px;
      text-align: left;
    }

    .bubble-text-center {
      left: 0px;
      text-align: center;
    }

    .button-x {
      right: 0;
      top: 0;
      border: none;
    }

    .pipe-one {
      padding: 12px;
      padding-left: 18px;
      margin-top: -6px;
      margin-left: -7px;
    }

    .pipe-two {
      padding: 11px;
      padding-left: 13px;
      margin-top: 1px;
      margin-left: 7px;
      width: 70px;
    }

    .power-one {
      padding: 9px;
      padding-left: 16px;
      margin-top: 4px;
      margin-left: -8px;
      padding-right: 5px;
    }

    .power-two {
      padding-top: 14px;
      padding-bottom: 12px;
      margin-top: 5px;
      margin-left: 23px;
      padding-left: 0px;
    }
  }
`
