import styled from 'styled-components'

export const Instructions = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ theme }) => theme.z.instructions};
  width: 360px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.gameInfo.background};
  font-size: 16px;
  line-height: 25px;
  color: ${({ theme }) => theme.gameInfo.color};
  font-family: 'MyriadPro-Regular', Arial, Helvetica, sans-serif;
  padding: 22px 30px;
  letter-spacing: 1px;

  .text {
    margin-bottom: 20px;
  }

  @media (max-width: 900px) {
    transform: translate(-50%, calc(-50% + 150px));
    width: 80%;
    max-width: 360px;
    font-size: 14px;
  }
`

const Arrow = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.z.instructions};
  width: 80px;

  svg {
    fill: ${({ theme }) => theme.arrow.fill};
  }
`

export const ArrowLeft = styled(Arrow)`
  left: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  animation: 1s infinite linear movement;

  @keyframes movement {
    0% {
      left: 0;
    }
    50% {
      left: 10px;
    }
    100% {
      left: 0;
    }
  }
`

export const ArrowRight = styled(Arrow)`
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  animation: 1s infinite linear movement2;

  @keyframes movement2 {
    0% {
      right: 0;
    }
    50% {
      right: 10px;
    }
    100% {
      right: 0;
    }
  }
`

export const ArrowTop = styled(Arrow)`
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  animation: 1s infinite linear movement3;

  @keyframes movement3 {
    0% {
      top: 20px;
    }
    50% {
      top: 30px;
    }
    100% {
      top: 20px;
    }
  }
`

export const ArrowBottom = styled(Arrow)`
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%) rotate(180deg);
  animation: 1s infinite linear movement4;

  @keyframes movement4 {
    0% {
      bottom: 20px;
    }
    50% {
      bottom: 30px;
    }
    100% {
      bottom: 20px;
    }
  }
`
