import styled from 'styled-components'
import { img } from '../../styles/assets/img'

export const TimerStyled = styled.div`
  background-image: url(${img.clock});
  background-size: cover;
`
export const TimerValue = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Metropolis-Light';
  font-size: 35px;
  color: ${({ theme }) => theme.timer.color};
  position: absolute;
  padding-top: 33px;

  &.shortTime {
    color: ${({ theme }) => theme.timer.shortTime};
  }
`
