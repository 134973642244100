export const WHITE = '#FFFFFF'
export const BLACK = '#000000'
export const GREY = '#BBBBBB'
export const LIGHT_GREY = '#FFFEEE'
export const RED = '#FF0000'
export const DARK_RED = '#990000'
export const INDIGO = '#1D428A'
export const BLUE = '#0091DA'
export const GREEN = '#78BE20'
export const ORANGE = '#EC7700'
