import styled from 'styled-components'

export const IconInfoStyled = styled.div`
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  filter: drop-shadow(2.121px 2.121px 0px ${({ theme }) => theme.iconInfo.shadow});
  background: no-repeat center/60% ${({ theme }) => theme.iconInfo.background}
    url(${({ theme }) => theme.iconInfo.img});
  border: 1px solid ${({ theme }) => theme.iconInfo.border};
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.z.instructions};
  margin-right: 17px;

  @media (max-width: 900px) {
    width: 30px;
    height: 30px;
  }
`

export const GameInfoStyled = styled.div`
  position: absolute;
  bottom: 0;
  right: 7%;
  z-index: ${({ theme }) => theme.z.instructions};
  margin-bottom: 20px;
  padding-bottom: 80px;

  .instructions-container {
    width: 378px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.gameInfo.background};
    font-size: 16px;
    line-height: 25px;
    color: ${({ theme }) => theme.gameInfo.color};
    z-index: ${({ theme }) => theme.z.instructions};
    font-family: 'MyriadPro-Regular', Arial, Helvetica, sans-serif;
    margin: 0 auto;
    padding: 22px 30px 22px 40px;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
  }
  ul > li {
    line-height: 18px;
    margin-bottom: 15px;
  }
  ul > li:last-child {
    margin-bottom: 0px;
  }

  .overlay {
    display: ${(props) => (props.showinfo ? 'block' : 'none')};
  }

  @media (max-width: 900px) {
    right: 9%;
    margin-bottom: 30px;
    padding-bottom: 60px;

    .instructions-container {
      width: 200px;
      font-size: 11px;
      padding: 17px 15px 17px 25px;
      letter-spacing: 0.5px;
    }

    ul {
      list-style-type: disc;
      list-style-position: outside;
    }

    ul > li {
      line-height: 13px;
      margin-bottom: 9px;
    }
  }

  .arrow-down {
    display: ${(props) => (props.showinfo ? 'block' : 'none')};
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    position: absolute;
    border-top: 20px solid ${({ theme }) => theme.gameInfo.background};
    right: 5%;
    bottom: 61px;
    z-index: ${({ theme }) => theme.z.instructions};
    @media (max-width: 900px) {
      bottom: 43px;
    }
  }
`
export const Overlay = styled.div`
  display: ${(props) => (props.showinfo ? 'block' : 'none')};
  position: fixed;
  z-index: -1;
  background-color: ${({ theme }) => theme.overlay.background};
  pointer-events: auto;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  top: 0;
`
