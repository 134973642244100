import { ACTION_TYPES } from 'actions/'
import { KEYS } from 'helpers/'

const INITIAL_STATE = {
  [KEYS.MODALS]: []
}

const openModal = (state, action) => {
  return {
    [KEYS.MODALS]: [{ id: action.id, content: action.content, stopsTimer: action.stopsTimer }]
  }
}

const closeModal = (state, action) => {
  const currentModals = [...state[KEYS.MODALS]]
  const filteredModals = currentModals.filter((modal) => modal.id !== action.id)

  return {
    [KEYS.MODALS]: [...filteredModals]
  }
}

const resetGame = (state) => {
  return {
    ...INITIAL_STATE,
    [KEYS.MODALS]: []
  }
}

const HANDLERS = {
  [ACTION_TYPES.OPEN_MODAL]: openModal,
  [ACTION_TYPES.CLOSE_MODAL]: closeModal,
  [ACTION_TYPES.RESET_GAME]: resetGame
}

export default (state = INITIAL_STATE, action) => HANDLERS?.[action.type]?.(state, action) ?? state
