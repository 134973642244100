import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { svg } from 'styles/assets/img'
import { Overlay, Button } from 'components/'
import { THEME } from 'styles/themes/light'
import { Instructions, ArrowLeft, ArrowRight, ArrowTop, ArrowBottom } from './styles'

const CanvasArrows = ({ vptCoords, onClose }) => {
  const handleClick = useCallback(() => {
    onClose()
  }, [onClose])

  const showVerticalArrows = useMemo(() => vptCoords?.tl?.y !== 0, [vptCoords])
  const showHorizontalArrows = useMemo(() => vptCoords?.tl?.x !== 0, [vptCoords])

  return (
    <>
      {(showVerticalArrows || showHorizontalArrows) && <Overlay $zIndex={THEME.z.instructions} />}
      {showVerticalArrows && (
        <>
          <ArrowTop>
            <svg.ChevronIcon />
          </ArrowTop>
          <ArrowBottom>
            <svg.ChevronIcon />
          </ArrowBottom>
        </>
      )}
      {(showVerticalArrows || showHorizontalArrows) && (
        <Instructions>
          <div className="text">
            To explore, drag the map in the directions indicated by the arrows.
          </div>
          <Button onClick={handleClick}>Close</Button>
        </Instructions>
      )}
      {showHorizontalArrows && (
        <>
          <ArrowLeft>
            <svg.ChevronIcon />
          </ArrowLeft>
          <ArrowRight>
            <svg.ChevronIcon />
          </ArrowRight>
        </>
      )}
    </>
  )
}

CanvasArrows.propTypes = {
  vptCoords: PropTypes.object,
  onClose: PropTypes.func
}

export default React.memo(CanvasArrows)
