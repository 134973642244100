import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useGetDimensions, useScenarioSelected, useProblemShown, useIsMobile } from 'hooks/'
import { img } from 'styles/assets/img'
import * as actions from 'actions/'
import { KEYS, uuidv4, PROBLEMS } from 'helpers/'
import {
  Canvas,
  StartButton,
  Timer,
  ImagesLoader,
  ModalAction,
  InfoGame,
  VMWareLogo,
  Overlay,
  CanvasArrows,
  DeviceRotation
} from 'components/'
import { GamePageStyled, Loader } from './styles'

const GamePage = () => {
  const [showStartButton, setShowStartButton] = useState(true)
  const [showInstructions, setShowInstructions] = useState(false)
  const [showTutorial, setShowTutorial] = useState(true)
  const [vptCoords, setVptCoords] = useState(undefined)
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const dimensions = useGetDimensions()
  const [loading, setLoading] = useState(0)
  const gameStatus = useSelector((state) => state.game?.[KEYS.GAME_STATUS].value)
  const selectedScenario = useScenarioSelected()
  const problemShown = useProblemShown()

  const handleImageLoad = useCallback(() => {
    setLoading((val) => val + 1)
  }, [])

  const handleGameStart = useCallback(() => {
    dispatch(actions.startGame())
  }, [dispatch])

  const handleHideStartButton = useCallback(() => {
    setShowStartButton(false)
  }, [])

  const handleToggleInstructions = useCallback(() => {
    setShowInstructions((val) => !val)
  }, [])

  const handleSelectScenario = useCallback(
    (scenario) => {
      dispatch(actions.setSelectedScenario(scenario))
      const modalId = uuidv4()
      dispatch(
        actions.openModal(
          modalId,
          <ModalAction id={modalId} scenario={scenario} problem={PROBLEMS.PROBLEM1} />,
          true
        )
      )
    },
    [dispatch]
  )

  const handleSolveProblem = useCallback(
    (problemSolved) => {
      dispatch(actions.completeProblem(selectedScenario, problemSolved))

      if (problemShown === PROBLEMS.PROBLEM1) {
        const modalId = uuidv4()
        dispatch(
          actions.openModal(
            modalId,
            <ModalAction id={modalId} scenario={selectedScenario} problem={PROBLEMS.PROBLEM2} />,
            false
          )
        )
      }
    },
    [dispatch, selectedScenario, problemShown]
  )

  const handleCanvasLoad = useCallback((value) => {
    setVptCoords(value)
  }, [])

  const handleCloseTutorial = useCallback(() => {
    setShowTutorial(false)
  }, [])

  return (
    <GamePageStyled>
      {(loading !== 7 || !vptCoords) && (
        <Loader>
          <img src={img.vmwLogoSq} alt="vmware" />
        </Loader>
      )}
      {isMobile && <DeviceRotation dimensions={dimensions} />}
      <Timer className={isMobile ? 'overCanvas timer mobile' : 'overCanvas timer mobile'} />

      {!!vptCoords && !showInstructions && showTutorial && (
        <CanvasArrows vptCoords={vptCoords} onClose={handleCloseTutorial} />
      )}

      {showStartButton && (
        <>
          <Overlay />
          <StartButton onClick={handleHideStartButton} className="overCanvas startButton">
            START
          </StartButton>
        </>
      )}
      <ImagesLoader onImageLoad={handleImageLoad} />
      {!!dimensions.width && !!dimensions.height && (
        <Canvas
          width={dimensions.width}
          height={dimensions.height}
          loading={loading !== 7}
          isMobile={isMobile}
          gameStatus={gameStatus}
          scenario={selectedScenario}
          problem={problemShown}
          shouldShowScenarios={!showStartButton}
          onSelectScenario={handleSelectScenario}
          onSolveProblem={handleSolveProblem}
          onGameStart={handleGameStart}
          onCanvasLoad={handleCanvasLoad}
        />
      )}
      <InfoGame
        isShowing={showInstructions}
        isMobile={isMobile}
        onToggleShowing={handleToggleInstructions}
      />
      <VMWareLogo />
    </GamePageStyled>
  )
}

export default GamePage
