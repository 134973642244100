import styled from 'styled-components'

export const ModalResultStyled = styled.div`
  width: 908px;
  height: 508px;
  background-image: linear-gradient(45deg, #0091da 0%, #00c1d5 100%);

  .top-blue-container-won,
  .top-blue-container-lost {
    background-color: #1d428a;
    padding: 62px 58px 28px;
  }

  .title {
    font-size: 55px;
    line-height: 55px;
    color: #ffffff;
    font-weight: 300;
    font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;
    margin-bottom: 14px;
  }

  .subtitle {
    width: 592px;
    font-size: 24px;
    line-height: 29px;
    color: #00c1d5;
    font-weight: 300;
    font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;
  }

  .subtitle-lost {
    font-size: 54px !important;
    line-height: 36px !important;
    color: #00c1d5;
  }

  .power-subtitle {
    width: 499px;
  }

  .coffee {
    position: absolute;
    right: 21%;
    width: 79px;
    top: 32%;
  }

  .lightbulb {
    position: absolute;
    right: 27%;
    width: 44px;
    top: 31%;
  }

  .power-person,
  .pipe-person {
    position: absolute;
    width: 160px;
    transform: scaleX(-1);
  }

  .pipe-person {
    right: 4%;
    top: 13%;
  }

  .power-person {
    right: 8%;
    top: 14%;
  }

  .pipe-bubble-container {
    position: absolute;
    right: 16%;
    top: 9%;
  }

  .power-bubble-container {
    position: absolute;
    right: 20.5%;
    top: 12%;
  }

  .power-bubble,
  .pipe-bubble {
    width: 200px;
    transform: scaleX(-1);
  }

  .pipe-bubble-text,
  .power-bubble-text {
    height: 34px;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    text-indent: -7px;
    color: #ffffff;
    font-weight: 300;
    font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;
    position: absolute;
    top: 20px;
    left: 42px;
  }

  .power-bubble-text {
    width: 119px;
  }

  .pipe-bubble-text {
    width: 133px;
  }

  .bottom-lightblue-container {
    display: flex;
    padding-top: 41px;
    padding-bottom: 33px;
  }

  .download-container {
    width: 60%;
    padding-left: 60px;
    padding-right: 69px;
    border-right: 1px solid #1d428a;
  }

  .again-container {
    width: 40%;
    padding-left: 93px;
    padding-right: 76px;
  }

  .download-text-won,
  .download-text-lost,
  .again-text-won,
  .again-text-lost {
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    font-weight: 300;
    font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;
    margin-bottom: 24px;
  }

  .download-text-won {
    width: 389px;
  }

  .download-text-lost {
    width: 318px;
  }

  .again-text-won,
  .again-text-lost {
    color: #1d428a;
  }

  .again-text-lost {
    width: 218px;
  }

  .footer-container {
    display: flex;
    justify-content: space-evenly;
    height: 64px;
    background-color: #ffffff;
  }

  .footer-text {
    font-size: 16px;
    line-height: 16px;
    color: #0091da;
    font-weight: 300;
    font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;
    padding-top: 25px;
    color: #1d428a;
  }

  .footer-icons-container {
    display: flex;
    justify-content: space-between;
    width: 100px;
  }

  .footer-icons-container a {
    display: flex;
  }

  .linkedin-icon,
  .twitter-icon {
    width: 20px;
  }

  .facebook-icon {
    width: 12px;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 950px) {
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;

    .title {
      font-size: 40px;
      line-height: 40px;
      margin: 0 auto 8px;
      padding-top: 35px;
      text-align: center;
    }

    .subtitle-lost {
      font-size: 40px !important;
      line-height: 36px !important;
      color: #00c1d5;
      text-align: left;
      width: 220px;
    }

    .subtitle,
    .power-subtitle {
      width: 246px;
      font-size: 20px;
      line-height: 22px;
      margin: 0 auto;
      text-align: center;
    }

    .subtitle-lost {
      width: 215px;
      text-align: left;
    }

    .top-blue-container-lost {
      padding: 10px 0;
    }

    .top-blue-container-won {
      padding: 0;
      padding-bottom: 82px;
      height: 200px;
    }

    .pipe-person,
    .power-person {
      right: 50%;
      top: 180px;
      transform: none;
      width: 88px;
    }

    .pipe-bubble-container,
    .power-bubble-container {
      top: 175px;
      left: calc(50% - 30px);
    }

    .power-bubble,
    .pipe-bubble {
      width: 140px;
      transform: none;
    }

    .pipe-bubble-text,
    .power-bubble-text {
      width: 97px;
      height: 24px;
      font-size: 11px;
      line-height: 11px;
      text-indent: -6px;
      top: 14px;
      left: 30px;
      transform: none;
    }

    .pipe-bubble-text {
      left: 30px;
    }

    .power-bubble-text {
      left: 35px;
    }

    .coffee {
      width: 46px;
      right: 42%;
      top: 230px;
      transform: none;
      left: 51%;
    }

    .lightbulb {
      width: 24px;
      right: 38%;
      top: 228px;
      left: 52%;
    }

    .bottom-lightblue-container {
      flex-direction: column;
      padding-top: 36px;
      padding-bottom: 36px;
      flex-direction: column;
      height: 50%;
      justify-content: center;
    }

    .download-container {
      width: 100%;
      padding: 0;
      border: none;
    }

    .download-text-won,
    .download-text-lost {
      width: 250px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin: 0 auto 10px;
    }

    .download-btn {
      width: 247px;
      height: 47px;
      border-radius: 3px;
      margin: 0 auto 30px;
      font-size: 16px;
      line-height: 16px;
    }

    .again-container {
      width: 100%;
      padding: 0;
    }

    .again-text-won,
    .again-text-lost {
      font-size: 16px;
      line-height: 19px;
      width: 263px;
      margin: 0 auto 10px;
      text-align: center;
      color: #ffffff;
    }

    .again-text-lost {
      width: 219px;
    }

    .again-btn-won,
    .again-btn-lost {
      width: 247px;
      height: 47px;
      border-radius: 3px;
      font-size: 16px;
      line-height: 19px;
      margin: 0 auto;
      display: flex;
      padding: 15px;
      padding-left: 65px;
    }

    .again-btn-won {
      padding-left: 65px;
    }

    .again-btn-lost {
      padding-left: 75px;
    }

    .footer-container {
      width: 100%;
      flex-direction: column;
      padding: 20px 0;
      height: 20%;
    }

    .footer-text {
      padding: 0;
      width: 250px;
      font-size: 12px;
      line-height: 15px;
      margin: 0 auto 10px;
      text-align: center;
      color: #1d428a;
    }

    .footer-icons-container {
      width: 100px;
      margin: 0 auto;
    }
  }

  @media (max-height: 666px) {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    .title {
      padding-top: 30px;
    }

    .top-blue-container-lost {
      height: 175px;
      padding: 0;
    }

    .bottom-lightblue-container {
      padding-top: 20px;
    }

    .footer-container {
      padding: 10px 0;
    }

    .pipe-bubble-container,
    .power-bubble-container {
      top: 153px;
      right: 21%;
    }

    .pipe-person,
    .power-person {
      top: 153px;
    }

    .coffee,
    .lightbulb {
      top: 205px;
      right: 34%;
      left: 51%;
    }

    .top-blue-container-won {
      padding-bottom: 75px;
      margin-top: -17px;
    }

    .again-container {
      margin-bottom: -25px;
    }

    .download-btn {
      margin-bottom: 10px;
    }
  }
`

export const StyledButtonLink = styled.button`
  cursor: pointer;
  border: none;
  background-color: initial;
  transition: ${({ theme }) => theme.buttons.transition};

  &:hover {
    transform: ${({ theme }) => theme.buttons.transform};
  }
`
