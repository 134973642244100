import styled from 'styled-components'

const Overlay = styled.div`
  position: absolute;
  z-index: ${({ theme, $zIndex }) => $zIndex ?? theme.z.overlay};
  background-color: ${({ theme }) => theme.overlay.background};
  pointer-events: auto;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
`

export default Overlay
