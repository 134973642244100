export const KEYS = {
  GAME_STATUS: 'game_status',
  PIPE_SCENARIO: 'pipe_scenario',
  POWER_SCENARIO: 'power_scenario',
  MODALS: 'modals',
  RESTARTS: 'restarts'
}

export const GAME_STATUS = {
  NEW: 'new',
  ONGOING: 'ongoing',
  FINISHED: 'finished'
}

export const PROBLEM_STATUS = {
  NOT_SHOWN: 'not_shown',
  ONGOING: 'ongoing',
  COMPLETED: 'completed'
}

export const SCENARIOS = {
  PIPE: 'pipe_scenario',
  POWER: 'power_scenario'
}

export const PROBLEMS = {
  PROBLEM1: 'problem1',
  PROBLEM2: 'problem2'
}

export const NEW_SCENARIO = {
  isSelected: false,
  isComplete: false,
  problems: {
    problem1: PROBLEM_STATUS.NOT_SHOWN,
    problem2: PROBLEM_STATUS.NOT_SHOWN
  }
}
