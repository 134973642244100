import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components/macro'
import { Provider } from 'react-redux'

import store from '../store/store'
import useTheme from '../styles/themes/useTheme'
import { GlobalStyles } from '../styles/common/global'
import { ModalStack, GameController } from 'components/'
import { LandingPage } from './landing'
import GamePage from './game'

import 'styles/common/fonts.css'

const ThemedApp = () => {
  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ModalStack />
      <GameController />
      <Router>
        <Switch>
          <Route path="/game">
            <GamePage />
          </Route>
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  )
}

export const App = () => (
  <Provider store={store}>
    <ThemedApp />
  </Provider>
)

export default App
