import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.z.rotationWarning};
  top: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 30px;

  font-family: 'MyriadPro-Regular', Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.rotation.color};
  background-color: ${({ theme }) => theme.rotation.background};

  svg,
  path {
    fill: ${({ theme }) => theme.rotation.icon};
  }
`
