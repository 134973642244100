import styled from 'styled-components'

export const GamePageStyled = styled.div`
  display: ${({ $loading }) => ($loading ? 'none' : 'block')};
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  flex: 1;

  .overCanvas {
    position: absolute;
    z-index: ${({ theme }) => theme.z.overoverlay};
  }

  .timer {
    top: 20px;
    left: 31%;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: 100px;
  }

  .mobile {
    left: 50% !important;
  }

  .startButton {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    border-radius: 8px;
  }
`

export const Loader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.z.loader};
  background-color: ${({ theme }) => theme.loader.background};

  img {
    animation: 5s infinite linear spin;
    width: 40%;
    max-width: 100px;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
