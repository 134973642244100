import { ACTION_TYPES } from 'actions/'
import { KEYS, GAME_STATUS, NEW_SCENARIO, PROBLEM_STATUS, PROBLEMS } from 'helpers/'

const INITIAL_STATE = {
  [KEYS.GAME_STATUS]: {
    value: GAME_STATUS.NEW
  },
  [KEYS.PIPE_SCENARIO]: NEW_SCENARIO,
  [KEYS.POWER_SCENARIO]: NEW_SCENARIO,
  [KEYS.RESTARTS]: 0
}

const resetGame = (state) => {
  const currentRestarts = state[KEYS.RESTARTS]

  return {
    ...INITIAL_STATE,
    [KEYS.GAME_STATUS]: {
      value: GAME_STATUS.NEW
    },
    [KEYS.PIPE_SCENARIO]: NEW_SCENARIO,
    [KEYS.POWER_SCENARIO]: NEW_SCENARIO,
    [KEYS.RESTARTS]: currentRestarts + 1
  }
}

const setGameStarted = (state) => {
  return {
    ...state,
    [KEYS.GAME_STATUS]: {
      ...state[KEYS.GAME_STATUS],
      value: GAME_STATUS.ONGOING
    }
  }
}

const setGameFinished = (state) => {
  return {
    ...state,
    [KEYS.GAME_STATUS]: {
      ...state[KEYS.GAME_STATUS],
      value: GAME_STATUS.FINISHED
    }
  }
}

const setSelectedScenario = (state, action) => {
  return {
    ...state,
    [action.option]: {
      ...state[action.option],
      isSelected: true
    }
  }
}

const showProblem = (state, action) => {
  const problems = state[action.option].problems
  let problemToShow = PROBLEMS.PROBLEM1
  if (problems?.problem1 === PROBLEM_STATUS.COMPLETED) {
    problemToShow = PROBLEMS.PROBLEM2
  }

  return {
    ...state,
    [action.option]: {
      ...state[action.option],
      problems: {
        ...state[action.option].problems,
        [problemToShow]: PROBLEM_STATUS.ONGOING
      }
    }
  }
}

const completeProblem = (state, action) => {
  let completeScenario = false

  if (action.problem === PROBLEMS.PROBLEM2) {
    completeScenario = true
  }

  return {
    ...state,
    [action.scenario]: {
      ...state[action.scenario],
      ...(completeScenario && { isSelected: false, isComplete: true }),
      problems: {
        ...state[action.scenario].problems,
        [action.problem]: PROBLEM_STATUS.COMPLETED
      }
    }
  }
}

const HANDLERS = {
  [ACTION_TYPES.RESET_GAME]: resetGame,
  [ACTION_TYPES.SET_GAME_STATUS_ONGOING]: setGameStarted,
  [ACTION_TYPES.SET_GAME_STATUS_FINISHED]: setGameFinished,
  [ACTION_TYPES.SET_SELECTED_SCENARIO]: setSelectedScenario,
  [ACTION_TYPES.SHOW_PROBLEM]: showProblem,
  [ACTION_TYPES.COMPLETE_PROBLEM]: completeProblem
}

export default (state = INITIAL_STATE, action) => HANDLERS?.[action.type]?.(state, action) ?? state
