import { fabric } from 'fabric'

export const CANVAS_IMAGE_WIDTH = 1920
export const CANVAS_IMAGE_HEIGHT = 1080
export const MAGNIFYING_GLASS_RADIUS = 100
export const MAGNIFYING_GLASS_BORDER_RADIUS = 105
export const DIALOG_PIPE = 'dialog_pipe'
export const DIALOG_POWER = 'dialog_power'

const magGlassBorder = new fabric.Circle({
  radius: MAGNIFYING_GLASS_BORDER_RADIUS,
  fill: 'red',
  originX: 'center',
  originY: 'center'
})

export const magGlassContent = new fabric.Circle({
  radius: MAGNIFYING_GLASS_RADIUS,
  originX: 'center',
  originY: 'center',
  globalCompositeOperation: 'destination-out'
})

export const magGlassCreator = () => {
  const group = new fabric.Group([magGlassBorder, magGlassContent], {
    left: -2 * MAGNIFYING_GLASS_BORDER_RADIUS,
    top: -2 * MAGNIFYING_GLASS_BORDER_RADIUS
  })

  group.id = 'magGlass'
  group.hoverCursor = 'none'

  return group
}

export const magGlass = magGlassCreator()

export const capPosition = (currentX, currentY, currentZoom, diffX, diffY, width, height) => {
  const minX =
    CANVAS_IMAGE_WIDTH * currentZoom > width ? width - CANVAS_IMAGE_WIDTH * currentZoom : 0
  const minY =
    CANVAS_IMAGE_HEIGHT * currentZoom > height ? height - CANVAS_IMAGE_HEIGHT * currentZoom : 0
  const intendedX = currentX + diffX
  const intendedY = currentY + diffY
  const newX = intendedX > 0 ? 0 : intendedX < minX ? minX : intendedX
  const newY = intendedY > 0 ? 0 : intendedY < minY ? minY : intendedY

  return [newX, newY]
}

const dialogCreator = (text, id, x, y, xTriangle) => {
  const rect = new fabric.Rect({
    width: 220,
    height: 105,
    fill: '#ffffff',
    originX: 'center',
    originY: 'center'
  })

  const textObj = new fabric.Text(text, {
    fontSize: 24,
    fontFamily: "'Metropolis-Regular', Arial, Helvetica, sans-serif",
    fontWeight: 'bold',
    originX: 'center',
    originY: 'center',
    fill: '#1d428a'
  })

  const arrow = new fabric.Triangle({
    width: 50,
    height: 30,
    fill: 'white',
    left: xTriangle,
    top: 40
  })
  arrow.rotate(180)

  const group = new fabric.Group([rect, textObj, arrow], {
    left: x,
    top: y
  })
  group.hoverCursor = 'pointer'
  group.id = id

  group.scale(0.7)

  return group
}

export const dialogPipe = dialogCreator('Water flow\nissue detected!', DIALOG_PIPE, 940, 530, -80)
export const dialogPower = dialogCreator('Electrical issue\ndetected!', DIALOG_POWER, 660, 220, 30)
