export const ACTION_TYPES = {
  RESET_GAME: 'RESET_GAME',
  SET_GAME_STATUS_ONGOING: 'SET_GAME_STATUS_ONGOING',
  SET_GAME_STATUS_FINISHED: 'SET_GAME_STATUS_FINISHED',
  SET_SELECTED_SCENARIO: 'SET_SELECTED_SCENARIO',
  SHOW_PROBLEM: 'SHOW_PROBLEM',
  COMPLETE_PROBLEM: 'COMPLETE_PROBLEM',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL'
}

export const resetGame = () => ({
  type: ACTION_TYPES.RESET_GAME
})

export const setSelectedScenario = (option) => ({
  type: ACTION_TYPES.SET_SELECTED_SCENARIO,
  option
})

export const startGame = () => ({
  type: ACTION_TYPES.SET_GAME_STATUS_ONGOING
})

export const finishGame = () => ({
  type: ACTION_TYPES.SET_GAME_STATUS_FINISHED
})

export const showProblem = (option) => ({
  type: ACTION_TYPES.SHOW_PROBLEM,
  option
})

export const completeProblem = (scenario, problem) => ({
  type: ACTION_TYPES.COMPLETE_PROBLEM,
  scenario,
  problem
})

export const openModal = (id, content, stopsTimer) => ({
  type: ACTION_TYPES.OPEN_MODAL,
  id,
  content,
  stopsTimer
})

export const closeModal = (id) => ({
  type: ACTION_TYPES.CLOSE_MODAL,
  id
})
