import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { img } from 'styles/assets/img'
import { PROBLEMS, SCENARIOS } from 'helpers/'
import { Image } from './styles'

const ImagesLoader = ({ onImageLoad }) => {
  const handleImageLoad = useCallback(() => {
    onImageLoad()
  }, [onImageLoad])

  return (
    <>
      <Image id="city-map" src={img.city} onLoad={handleImageLoad} />
      <Image
        id={`problem-${SCENARIOS.PIPE}-${PROBLEMS.PROBLEM1}`}
        src={img.background[SCENARIOS.PIPE][PROBLEMS.PROBLEM1]}
        onLoad={handleImageLoad}
      />
      <Image
        id={`problem-${SCENARIOS.PIPE}-${PROBLEMS.PROBLEM2}`}
        src={img.background[SCENARIOS.PIPE][PROBLEMS.PROBLEM2]}
        onLoad={handleImageLoad}
      />
      <Image
        id={`problem-${SCENARIOS.POWER}-${PROBLEMS.PROBLEM1}`}
        src={img.background[SCENARIOS.POWER][PROBLEMS.PROBLEM1]}
        onLoad={handleImageLoad}
      />
      <Image
        id={`problem-${SCENARIOS.POWER}-${PROBLEMS.PROBLEM2}`}
        src={img.background[SCENARIOS.POWER][PROBLEMS.PROBLEM2]}
        onLoad={handleImageLoad}
      />
      <Image
        id={`problem-fixed-${SCENARIOS.PIPE}`}
        src={img.background[SCENARIOS.PIPE].fixed}
        onLoad={handleImageLoad}
      />
      <Image
        id={`problem-fixed-${SCENARIOS.POWER}`}
        src={img.background[SCENARIOS.POWER].fixed}
        onLoad={handleImageLoad}
      />
    </>
  )
}

ImagesLoader.propTypes = {
  onImageLoad: PropTypes.func
}

export default React.memo(ImagesLoader)
