import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button } from 'components/'

export const GamePageStyled = styled.div`
  background-color: ${({ theme }) => theme.main.background};
`

export const TitleStyled = styled.div`
  font-size: 45px;
  line-height: 60px;
  color: #1d428a;
  font-weight: 300;
  font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;
  text-align: center;
  width: 100%;
  margin: 0 auto 45px;
  span {
    color: white;
    font-size: 90px;
    line-height: 85px;
  }
  @media (max-width: 900px) {
    width: 286px;
    line-height: 24px;
    font-size: 20px;
    margin-bottom: 29px;
    span {
      font-size: 40px;
      line-height: 48px;
    }
  }
`

export const SubtitleStyled = styled.div`
  font-size: 30px;
  line-height: 35px;
  color: #ffffff;
  font-weight: 300;
  font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;
  text-align: center;
  width: 624px;
  margin: 0 auto 58px;
  @media (max-width: 900px) {
    width: 234px;
    line-height: 20px;
    font-size: 16px;
    margin-bottom: 29px;
  }
`

export const ButtonEnter = styled(Button)`
  width: 227px;
  height: 70px;
  margin: 0 auto;
  line-height: 30px;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  color: #fbfbfc;
  font-size: 30px;
  font-family: 'Metropolis-Regular', Arial, Helvetica, sans-serif;
  line-height: 2px;
  transition: ${({ theme }) => theme.buttons.transition};

  &:hover {
    transform: ${({ theme }) => theme.buttons.transform};
  }

  @media (max-width: 900px) {
    width: 244px;
    height: 47px;
    line-height: 0;
    border-radius: 5px;
    font-size: 16px;
  }
`

export const LinkEnter = styled(Link)`
  margin: 0 auto;
`

export const LandingPageStyled = styled.div`
  background: url(${({ theme }) => theme.landing.background});
  background-color: #1fc0d4;
  position: relative;
  overflow: hidden;
  background-size: 1920px 125%;
  background-position: 50% 55%;
  background-repeat: no-repeat;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 900px) {
    background-size: cover;
    background-position: center;
  }
`
