import { img, svg } from 'styles/assets'
import * as colors from '../common/colors'

export const THEME = {
  main: {
    background: colors.WHITE,
    color: colors.BLACK
  },
  landing: {
    background: img.landingBackground
  },
  z: {
    modal: 5000,
    xray: 5,
    city: 10,
    underoverlay: 15,
    overlay: 20,
    overoverlay: 25,
    instructions: 30,
    rotationWarning: 5100,
    loader: 100
  },
  modal: {
    borderRadius: '3px',
    closeIconColor: colors.BLACK,
    background: colors.BLUE,
    text: colors.WHITE
  },
  button: {
    background: colors.INDIGO,
    color: colors.WHITE
  },
  overlay: {
    background: 'rgba(0,0,0, 0.5)'
  },
  vmware: {
    svg: svg.VMWareLogo
  },
  buttonX: {
    background: colors.BLUE,
    border: colors.WHITE,
    color: colors.WHITE
  },
  downloadButton: {
    background: colors.GREEN,
    border: colors.WHITE,
    color: colors.WHITE
  },
  iconInfo: {
    background: colors.WHITE,
    shadow: colors.INDIGO,
    border: colors.INDIGO,
    img: img.IconInfo
  },
  gameInfo: {
    background: colors.WHITE,
    color: colors.INDIGO
  },
  bubble: {
    text: colors.WHITE,
    pipe: {
      background: colors.GREEN
    },
    power: {
      background: colors.ORANGE
    }
  },
  buttons: {
    transition: 'all 0.2s ease-in-out',
    transform: 'scale(1.1)'
  },
  timer: {
    color: colors.INDIGO,
    shortTime: colors.DARK_RED
  },
  loader: {
    background: colors.INDIGO
  },
  arrow: {
    fill: colors.WHITE
  },
  rotation: {
    background: colors.BLUE,
    color: colors.WHITE,
    icon: colors.WHITE
  }
}
